@import '~@fortawesome/fontawesome-free/css/all.css';

/* * {
  border: 1px solid #fff;
} */

:root{
  --bgheight:100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: all 3s;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: all 3s;
}

.panel {
  position: absolute;
  left: 0;
  width: 180px;
  top: 0;
  bottom: 0;
  box-shadow: 0px 0px 15px black;
  background: white;
  padding: 10px;
  -webkit-transform-origin: 0% 100%;
  -webkit-transform: scaleX(0.00001);
  -webkit-transition: -webkit-transform 2s ease-in-out;
  transform-origin: 0% 100%;
  transform:  scaleX(0.00001);
  transition: transform 2s ease-in-out;
  outline: 0;
}
.panel:target {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.slide-in {
  animation: slide-in 2s forwards;
  -webkit-animation: slide-in 2s forwards;
}

.slide-out {
  animation: slide-out 2s forwards;
  -webkit-animation: slide-out 2s forwards;
}
  
@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}
  
@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-100%); }
}



.slideUp-enter {
  transform: translateY(1000px);
}
.slideUp-enter-active {
  transform: translateY(0%);
  transition: all 1s;
}
.slideUp-exit {
  transform: translateY(0%);
}
.slideUp-exit-active {
  transform: translateY(1000px);
  transition: all 1s;
}

.slideIn-enter {
  transform: translateX(-100%);
}
.slideIn-enter-active {
  transform: translateX(0%);
  transition: all 1s forwards;
}
.slideIn-exit {
  transform: translateX(0%);
}
.slideIn-exit-active {
  transform: translateX(-100%);
  transition: all 1s forwards;
}

.tech-stack-container {
  display: flex;
  overflow-x: scroll;
  padding: 1rem 0;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.program-icon {
  text-align: center;
}

.icon {
  width: 64px;
  height: 64px;
}

.language {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
}

.skill-table {
  color:khaki;
  /* width: 100%; */
  /* max-width: 300px; */
  /* margin: 5 auto; */
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid #d3c1c183;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    background-color: #232324;
  }
}


/* Responsive styles */
@media (max-width: 600px) {
  .skill-table {
    max-width: 100%;
  }
}

.skill-box {
  /* sx={{ maxWidth: 300, marginBottom: '16px' }} boxShadow={2} */
  max-width: 300px;
  margin-bottom: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 20px;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
}

.skill-card {
  &:hover {
    background-color: #232324;
  }
}

