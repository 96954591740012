.subintro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  
  .subtext {
    font-size: 24px;
    text-align: left;
    margin: 20px;
    opacity: 0;
    transform: translateY(20px); /* Slide down slightly */
    animation: fadeInUp 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    animation-delay: 1s;
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .subintro-container {
      margin-top: 24px;
      height: 20vw;
      width: 100%;
      font-size: 12px;
    }

    .subtext {
      font-size: 12px;
    }

  }