
:root {
  --nav-tabs-height: 60px;
  --icon-grid-size:35vw;
  --list-size:calc(var(--icon-grid-size) / 6);
  --icon-size:calc(var(--list-size) / 2);
  --icons-size:calc(var(--icon-size) + 10px);
  --toggle-size:calc(var(--icons-size) * 2);
  --icons-count:9;
  
}

@media (max-width: 768px) {
  :root {
    --nav-tabs-height: 10%; /* Example value for mobile */
    --icon-grid-size: 85vw; /* Use 100% viewport width */
    --list-size: calc(var(--icon-grid-size) / 7);
    --icon-size: calc(var(--list-size) / 2);
    --icons-size: calc(var(--icon-size) + 10px);
    --toggle-size: calc(var(--icons-size) * 2);
  }
}

.home-vanta-bg {
  height: 100%;
  width:100vw;
    margin: 0;
    padding: 0;
}


/*
:root {
  --nav-tabs-height: 60px;
  --icon-grid-size:50vw;
  --list-size:100px;
  --icon-size:50px;
  --icons-size:60px;
  --toggle-size:120px;
  --icons-count:9;
}
*/
.home-container {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    padding: 24;
}
  

/*
  .background-image {
     position: absolute; 
     top: 0;
    left: 0; 
     width: 100%;
    height: 100%; 
    background-repeat: no-repeat;
     background-size: cover; 
     z-index: -1; 
  }
  */
  .content-container {
    background-color: rgba(56, 55, 55, 0);
    padding: 20px;
    max-width: 100%;
  }
  
  .intro-container {
    align-items: left;
    margin-left: 48px;
    text-align: left;
    padding-top: 0;
    max-width: 100%;
    margin-top: -48px;
  }
  
  @media (max-width: 768px) {
    .home {
      width: 100vw;
      height: auto;
    }
      .home-container {
        width: 100v;
        height: auto;
        padding: 12;
      }
      .content-container {
        padding: 10px;
        height: 45vh;
        max-width: 100%;
      }

      .animation-container {
        /* height: 50vh; */
      }

      .intro-container {
        margin-left: 24px;
        max-width: 100%;
      }
  
  }

  .highlight {
    display: inline;
    font-family: sans-serif;
    color: #ffd700;
  }
  
  .subtext {
    font-family: sans-serif;
    color: #ffffff;
  }
  
  .circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background-color: rgba(252, 229, 229, 0.007);
    margin: 0 auto;
  }
  
  .center-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .language-icons,
  .database-icons {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    transform-origin: center;
  }
  
  .icon {
    width: var(--icon-size);
    height: var(--icon-size);
    margin: 10px;
  }


  
  .language-icons {
    transform: rotate(-15deg);
  }
  
  .database-icons {
    /* transform: rotate(30deg);
    right: -100px;
    top: 50%;
    transform: translate(0, -50%); */
  }


  @media (max-width: 600px) {
    .icon {
      width: 30px;
      height: 30px;
    }

    .row .empty {
      width: 30px;
      height: 30px;
      margin: 10px;
    }

    .circle {
      width: 200px;
    height: 200px;
    }
  }

  /* Initial JavaScript Fade-in */
  .icon-javascript {
    animation: fade-in 1s ease-in-out;
  }

/* Curved Entry Animation */
@keyframes curved-entry {
  0% {
    transform: translateX(-100%) translateY(-100%)
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

.icon-entry {
  /* animation: curved-entry 3s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
  /* transform-origin: center; */
}

/* Circular Rotation */
@keyframes circular-rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-container.rotate {
  /* animation: circular-rotation 10s linear infinite; */
  animation: circular-rotation 10s linear 2s;
}

.rotate-animation {
  animation: rotate 2s linear forwards;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.vanta-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - var(--nav-tabs-height));
  z-index: -1;
}

@keyframes react-icon-animation {
  0% {
    transform: translateX(var(--react-initial-x)) translateY(var(--react-initial-y));
  }
  100% {
    transform: translateX(var(--react-actual-x)) translateY(var(--react-actual-y));
  }
}


.menu{
  position:relative;
 width:var(--icon-grid-size);
  height:var(--icon-grid-size);
  display:flex;
  align-items:center;
  justify-content:center;
}
.menu .toggle{
  position:relative;
  height: var(--toggle-size);
  width: var(--toggle-size);
  background:#fff;
  border-radius:50%;
  box-shadow:0 3px 4px rgba(0,0,0,0.15);
  display:flex;
  align-items:center;
  justify-content:center;
  color:#333;
  font-size:2rem;
  cursor:pointer;
  transition:1.20s;
  z-index:5;
}
.menu.active .toggle{
  transform:rotate(360deg);
  box-shadow: 0 6px 8px rgba(0,0,0,0.15),
    0 0 0 2px #333,
    0 0 0 8px #fff;
}
.menu li{
  
  position:absolute;
  left:0;
  list-style:none;
  transition:1.2s;
  transform:rotate(calc(360deg/var(--icons-count) * var(--i)));
  transform-origin:calc(var(--icon-grid-size)/2);
  scale:0;
  transition-delay: calc(0.05s * var(--i));
  width: var(--list-size);
  height: var(--list-size);
}
.menu.active li{
  scale:1;
}

.menu li .icon-item{
  position:relative;
  display:flex;
 transform:rotate(calc(360deg/-9 * var(--i)));
  background-color:#fff;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:50%;
  font-size:1.75rem;
  color:var(--clr);
  box-shadow:0 0 0 2px var(--clr), 0 0 0 6px #fff;
  transition:0.8s;
}
.menu li:hover .icon-item{
  font-size:2.5rem;
  box-shadow:0 0 0 4px #ffd700, 0 0 0 12px #fff;
}