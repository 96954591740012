.content {
    display: flex;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-size: 35px;
    line-height: 40px;
    color: #ecf0f1;
  }
  
  .content__container {
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  
  .content__container:before,
  .content__container:after {
    content: "[";
    color: #d6b80d;
    font-size: 42px;
    line-height: 40px;
    animation: opacity 2s infinite;
  }
  
  .content__container:after {
    content: "]";
  }
  
  .content__container__text {
    display: inline;
    margin: 0;
  }
  
  .content__container__item-container {
    width: 150px; /* Adjust width based on your content */
    overflow: hidden;
  }
  
  .content__container__item {
    display: inline-block;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 4s ease-in-out; /* Fade-in effect transition */
  }
  
  .fade-in {
    opacity: 1;
  }
  
  @keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @keyframes scroll {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
  }
  