@import "https://fonts.googleapis.com/css?family=Baloo+Paaji";

.container {
  font-family: "Baloo Paaji", cursive;
  /* display: flex; */
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
  text-align: left;
    width: 100%;
    margin-bottom:100px;
  /* height: 50vh; */
  /* position: relative; */
  /* margin-left:50px; */
}


h1 {
    font-size: 60px;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
}

.title {
    font-size: 40px;
    width: 100%;
    margin: 0;
  }

  @media (max-width: 768px) {
    .container {
        /* display: unset; */
        width: 100%;
        margin-bottom:50px;
        /* margin-left:25px; */
        /* height: 200px; */

    }
    h1 {
        font-size: 30px;
        width: 100%;
    }
    .title {
        font-size: 20px;
        width: 100%;
    }
  }
  .role {
    /* color:chocolate; */
  }

h1 span,
h2 span,
h3 span {
  width: 100%;
  float: left;
  color: #ffffff;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  transform: translateY(-50px);
  opacity: 0;
  animation-name: titleAnimation;
  animation-timing-function: ease;
  animation-duration: 4s;
}

h2 span {
    animation-delay: 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

h1 span:first-child {
    color: #ffe221;
    animation-delay: 0.7s;
    -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  }

h3 span:first-child {
  animation-delay: 0.8s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

h3 span:last-child {
    animation-delay: 0.9s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }



.usechrome {
  font-size: 10px;
  color: #fff;
  font-family: helvetica, arial;
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: left;
  left: 0;
}

@keyframes titleAnimation {
  0% {
    transform: translateY(-50px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  20% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  /* 80% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
    clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
  } */

  80%, 100% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
}

.myself {
    display: inline;
    width: 100%;
    margin-right: 8px;
  }
  .role {
    display: inline;
    width: 100%;
  }